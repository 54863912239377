<template lang="pug">
    layout_landpage
        header.flex_content-vertical.jc_flex-end
            .bg.image(:style="project.background|ress|background")
            .column
                .grid.grid-phi
                    div
                        .wrapper
                            .margin-bottom--lg
                                strong.text--xs.text--ls-sm.text--uppercase.text--w400 Proyecto
                            h1 {{ project.name }}
                    div
        section.description
            .column
                .grid.grid-phi.phi-reverse
                    div
                        .wrapper.flex_content-horizontal.jc_center
                            .frame_description
                                .frame_wrapper
                                    .margin-bottom--xl
                                        .table_info
                                            .tr
                                                .tdl
                                                    span Cliente
                                                .tdr
                                                    span {{ project.client }}
                                            .tr
                                                .tdl
                                                    span Lugar
                                                .tdr
                                                    span {{ project.place }}
                                            .tr
                                                .tdl
                                                    span Fecha
                                                .tdr
                                                    span {{ project.date }}
                                    p {{ project.description }}
                                    
                    div.details
                        .wrapper
                            .margin-bottom--xxl
                                .sec_title
                                    span 01
                                    h3 Información
                            tabs
                                tab(name="Detalles" :selected="true")
                                    .margin-bottom--xl
                                        h4.text--sm.text--font_primary.text--uppercase.text--ls-sm Necesidad
                                        p {{ project.need }}
                                    .margin-bottom--xl
                                        h4.text--sm.text--font_primary.text--uppercase.text--ls-sm Solución
                                        p {{ project.solution }}
                                tab(name="Planos")
                                    .blueprints.flex_content-horizontal
                                        figure(v-for="blueprint in project.blueprints").blueprint
                                            img(:src="blueprint|ress" :alt="blueprint.description")
                                            figcaption.off_screen
                                                span {{ blueprint.description }}
                                tab(name="Video" v-if="project.video")
                                    iframe(:src="project.video" frameborder="0" allowFullScreen mozallowfullscreen webkitAllowFullScreen).video
        section.gallery
            .column
                .margin-bottom--xxl
                    .sec_title.sec_title--center
                        span 02
                        h3 Galería de Fotos
                .gallery_slider
                    slick(ref="slick" :options="slickOptions")
                        .image(v-for="img in project.gallery")
                            .frame_image
                                img(:data-lazy="img|ress" :alt="img.description")
        nav.nav_projects
            .column
                .sec_title.sec_title--center
                    span 03
                    h3 Más Proyectos
                .margin-top--xxl
                    ul.flex_content-horizontal.jc_center
                        li
                            slug-link(
                                ref="prevProject"
                                route="landpage_project"
                                :name="prevProject.name"
                                :pk="prevProject.pk"
                            ).link_project
                                .icon
                                .text
                                    span {{ prevProject.name }}
                        li
                            slug-link(
                                ref="nextProject"
                                route="landpage_project"
                                :name="nextProject.name"
                                :pk="nextProject.pk"
                            ).link_project
                                .text
                                    span {{ nextProject.name }}
                                .icon
</template>

<script>
import Landpage from '@/views/layouts/Landpage'
import Tabs from '@/components/Tabs'
import Tab from '@/components/Tab'
import Slick from 'vue-slick'
import SlugLink from '@/components/SlugLink'

export default {
    name: 'landpage_project',
    props: ['pk'],
    components: {
        layout_landpage: Landpage,
        Tabs,
        Tab,
        Slick,
        SlugLink
    },
    data() {
        return {
            elVideo: null,
            slickOptions: {
                slidesToShow: 1,
                dots: true,
                fade: true,
                lazyLoad: 'ondemand',
                responsive: [
                    {
                        breakpoint: 414,
                        settings: {
                            arrows: false,
                            dots: true
                        }
                    }
                ]
            },
            allProjects: [],
            currentIndex: 0,
            prevProject: {},
            nextProject: {}
        }
    },
    created() {
        this.navProjects()
    },
    mounted() {
        this.elVideo = this.$el.querySelector('#video')
    },
    metaInfo() {
        return {
            title: this.project.name,
            meta: this.project.meta
        }
    },
    computed: {
        project() {
            return this.$store.getters.singleProject(this.pk)
        }
    },
    methods: {
        handleTab(selectedTab) {
            if (selectedTab != "video")
                this.pauseVideo()
        },
        playVideo() {
            if (this.elVideo.paused) return
            this.elVideo.play()
        },
        pauseVideo() {
            if (!this.elVideo.paused) return
            this.elVideo.pause()
        },
        navProjects() {
            this.allProjects = this.$store.getters.allProjects
            this.allProjects.forEach((project, index) => {
                if (project.pk == this.project.pk) {
                    this.currentIndex = index
                    return
                }
            })
            this.nextProject = this.nextItem(this.currentIndex, this.allProjects)
            this.prevProject = this.prevItem(this.currentIndex, this.allProjects)
        },
        prevItem(index, allProjects) {
            if (index == 0) {
                index = allProjects.length
            }
            index = index - 1
            return allProjects[index]
        },
        nextItem(index, allProjects) {
            index = index + 1
            index = index % allProjects.length
            return allProjects[index]
        },
        animatePev() {
            this.$el.classList.add('re_init', 'prevPage')
            setTimeout(() => {
                this.$el.classList.remove('re_init', 'prevPage')
            }, 1500)
        },
        animateNext() {
            this.$el.classList.add('re_init', 'nextPage')
            setTimeout(() => {
                this.$el.classList.remove('re_init', 'nextPage')
            }, 1500)
        },
        reInit() {
            const prevIndex = this.currentIndex
            this.navProjects()
            const lastIndex = (this.allProjects.length - 1)
            if (prevIndex == lastIndex) {
                if (this.currentIndex == 0) {
                    this.animateNext()
                } else {
                    this.animatePev()
                }
            } else if (prevIndex == 0) {
                if (this.currentIndex == lastIndex) {
                    this.animatePev()
                } else {
                    this.animateNext()
                }
            } else {
                if (this.currentIndex > prevIndex) {
                    this.animateNext()
                } else {
                    this.animatePev()
                }
            }
            this.$refs.slick.destroy()
            this.$nextTick(() => {
                this.$refs.prevProject.setRoute()
                this.$refs.nextProject.setRoute()
                this.$refs.slick.create()
            })
        }
    },
    watch: {
        '$route' (to, from) {
            if (from.name == 'landpage_project' && to.name == 'landpage_project') {
                this.reInit()
            } else if (from.name == 'page_projects' && to.name == 'landpage_project') {
                if (this.$refs.slick) {
                    this.reInit()
                }
            }
        }
    }
}
</script>