<template lang="pug">
    .tabs
        .tabs-title
            ul.flex_content-horizontal
                li(v-for="tab in tabs" :class="{ 'is_active' : tab.isActive }")
                    a(:href="tab.href" @click.prevent="selectTab(tab)").text--xs.text--ls-sm.text--uppercase {{ tab.name }}
        .tabs-details
            slot
</template>

<script>
export default {
    name: 'component_tabs',
    data() {
        return {
            tabs: []
        }
    },
    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.name == selectedTab.name)
            })
        }
    },
    created() {
        this.tabs = this.$children
    },
    watch: {
      '$route' (to, from) {
        this.selectTab(this.tabs[0])
      }
    }
}
</script>