<template lang="pug">
    .layout_landpage
        slot
        include_mainFooter
</template>

<script>
import MainFooter from '@/views/includes/MainFooter'

export default {
    name: 'layout_landpage',
    components: {
        include_mainFooter: MainFooter
    }
}
</script>